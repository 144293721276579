@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body,
.App {
  background-color: #141120;
  font-family: "Poppins", sans-serif;
}

.monospace {
  font-family: "Source Code Pro", monospace;
}

.bold {
  font-weight: 600 !important;
}

.boldest {
  font-weight: 800 !important;
}

#name {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
  /* font-size: 6vw; */
  letter-spacing: -3px;
  line-height: 0.9em;
  color: white;
}

#top-text {
  font-family: "Source Code Pro", monospace;
  text-transform: uppercase;
  /* font-size: 24px; */
  letter-spacing: 2px;
  /* line-height: 1.8em; */
  color: white;
}

.timeline-bubble {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 5px solid #ffffff;
}
.timeline-date {
  line-height: 100px;
  font-family: "Source Code Pro", monospace;
}
.timeline-description {
  /* padding: 10px;
  font-family: "Source Code Pro", monospace;
  background-color: #272449;
  box-shadow: rgba(255, 255, 255, 0.089) 0px 0px 3px 3px;
  border-radius: 10px;
  border-bottom: white 2px solid; */
}

.timeline-bullet {
  list-style-position: outside;
}

.text-muted-light {
  color: #ffffffb2;
}

.card-image-overlay {
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
}

.card-image {
  cursor: pointer;
  transition: 0.3s;
}

.card-image:hover {
  transform: scale(1.01);
  transition: 0.3s;
}

.card-title {
  cursor: pointer;
  transition: 0.3s;
}

.card-title:hover {
  color: #ffffffa9;
  transition: 0.3s;
}

.overlay-button {
  display: block;
  padding: 10px;
  background-color: #000000af;
  transition: 0.3s;
}
.overlay-button:hover {
  background-color: #000000cf;

  transition: 0.3s;
}

.overlay-button-image {
  width: 30px;
  height: 30px;
  filter: invert(100%);
}
